@import './Variables.less';

.transitionWrapper {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: @fullPageModalZIndex;

  overflow-y: auto;

  transition-property: transform, opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
}

.appear {
  opacity: 0;
  transform: translate(0,100%);
  -ms-transform: translate(0,100%);
}

.appearActive {
  opacity: 1;
  transform: translate(0,0);
  -ms-transform: translate(0,0);
}

.enter {
  opacity: 0;
  transform: translate(0,100%);
  -ms-transform: translate(0,100%);
}

.enterActive {
  opacity: 1;
  transform: translate(0,0);
  -ms-transform: translate(0,0);
}

.leave {
  opacity: 1;
  transform: translate(0,0);
  -ms-transform: translate(0,0);
}

.leaveActive {
  opacity: 0;
  transform: translate(0,100%);
  -ms-transform: translate(0,100%);
}

// todo: move below to somewhere else, this file is for the slide up transition
.dragContainer {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background: @white;
  top: 0 !important;
  left: 0 !important;
}

.content {
  margin: 0 auto;
}

.header {
  display: none;
}

.placeholder {
  display: block;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: @fullPageModalOverlayZIndex;
}
