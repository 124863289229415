@import "~nucleus-core/less/Utility.less";
@import './Variables.less';

.close {
  color: @black;
  position: absolute;
  right: 1em;
  top: 50%;
  margin-top: -0.6em;
  font-size: 1.1em;
  font-weight: 300;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: @cventBlue;
    cursor: pointer;
  }
}

// moved from SlidingDialog.less

.slideParentPanel {
  left: 0;
  transition: left @hcSlidingDialogTransitionDuration;

  &.slidePanel {
    left: -100%;
  }
}

.slideChildPanel {
  left: 100%;
  transition: left @hcSlidingDialogTransitionDuration;

  &.slidePanel {
    left: 0;
  }
}

.panelGroup {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: @white;
}

.parentPanel {
  .slideParentPanel();

  position: absolute;
  width: 100%;
  padding-bottom: 100px;
}

.childPanel {
  .parentPanel();
  .slideChildPanel();
}

.panelHeader {
  .clearfix();
  position: relative;
  top: 0;
  width: 100%;
  z-index: @dialogFixedHeaderZIndex;
  color: @black;
  font-weight: 700;
  text-align: center;
  font-size: 1.15em;
  padding: 0.5em 0;
  margin: 0;
  border-bottom: solid 1px @asapLighterGray;
}

.parentPanelHeader {
  .panelHeader();
  .slideParentPanel();
}

.childPanelHeader {
  .panelHeader();
  .slideChildPanel();

  padding: 0.5em 1.2em 0.5em 2em;
}

.panelContent {
  padding: .5em 1.2em;
}

.slidingIconContainer {
  margin-top: -0.6em;
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
}

.filtersItemButtonContainer {
  border-bottom: 1px solid @asapLighterGray;
  position:  relative;
  clear: both;
}

.filtersItemButton {
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0.5em 0;
  font-size: 1em;
}

.filtersHeader {
  padding-right: 1.5em;
  display: inline-block;
}

.button {
  background: @filtersButtonBackgroundColor;
  border: solid 1px @closeIconColor;
  color: @black;
  font-size: 1em;
  width: 100%;
  height: 2.5em;
  cursor: pointer;
  border-radius: 3px;
}

.backIconLink {
  color: @black;
  position: absolute;
  line-height: 1;
  font-size: 1.1em;
  top: 50%;
  left: 1em;
  margin-top: -0.5em;

  &:hover {
    text-decoration: none;
    color: @cventBlue;
    cursor: pointer;
  }
}
