@import  (reference) "Variables.less";

.button {
  background: @buttonBgColor;
  border: solid 1px #ebebeb;
  color: @buttonFontColor;
  font-size: 1em;
  width: 100%;
  height: 2.5em;
  cursor: pointer;

  &:disabled {
    background: transparent;
    border: none;
    color: @buttonDisabledColor;
    padding: .35em 0;
  }
}
