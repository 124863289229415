@import "./Variables.less";
@import "./CvFormElement.less";

// ----- replacing variables "~nucleus-core/daypicker/styles/Variables.less" here
@import '~nucleus-core/less/VariablesColors.less';
@calendarWidth: 220px;
@rangeCalendarWidth: 450px;
@calendarBorderRadius: 5px;
@calendarBorderColor: #9fb1b9;
@calendarBackground: #fff;
@dayText: #000;
@daysHeaderText: #4c4c4c;
@daysHeaderBackground: #e9edef;
@todayBackground: #e9edef;
@selectedText: #fff;
@selectedDayBackground: #808080;
@hoverBackground: #e9edef;
@inactiveText: #808080;
@disabledBackground: #eee;
@textboxBorderHighlight: @cventBlue;
// ------ replace ends here

@import '~nucleus-core/less/cv/DayPicker.less';

.dateInputWrapper {
  position: relative;
  padding-right: 0;
  display: block;
}

.textboxDate {
  .textbox();
}

.calendar {
  z-index: 1000;
  clear: both;
  position: absolute;
  width: auto;
  border: 1px solid @outerGridColor;
  background: @calendarBackground;
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,.3);
  margin-top: 5px;
  font-size: 14px;
  padding: .25em .25em .75em .25em;
  font-family: @datePickerFontFamily;
  &:before, &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-top: 0;
  }
}

.monthWrapper {
  border-spacing: .25em;
  border-collapse: separate;
}

.monthBody {
  font-size: 0.79em;
}

.day {
  line-height: 1;
  padding: .8em;
  display: table-cell;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  color: @dayText;
  &:hover {
    background: @dayBackground;
    color: @dayTextHover;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 3em;
  }
}

.weekdays {
  display: table-header-group;
  background: @daysHeaderBackground;
}
.weekdaysRow {
  display: table-row;
}
.weekday {
  color: @daysHeaderText;

  abbr[title] {
    text-decoration: none;
  }
}

// Days outside the current month
.outside {
  color: @inactiveDayText;
  border-radius: 3em;
  &:hover {
    background: @inactiveBackgroundHover;
    color: @inactiveTextHover;
  }
}

.today {
  border-radius: 3em;
  font-weight: bold;
  color: @selectedTodayText;
  background: transparent;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

.selected {
  color: @selectedText;
  background: @selectedBackground;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 3em;
  &:hover {
    color: @selectedTextHover;
    background: @selectedBackgroundHover;
  }
}

.captionDropdown {
  border: none;
  background: none;
  color: @monthYearSelectText;
}

.captionDropdownWrapper label {
  padding: 0;
}

.caption {
  font-size: 1.15em;
  padding: .8em 1.5em .25em 1.5em;
}

.navButtonPrevious {
  left: .4em;
}

.navButtonNext {
  right: .4em;
}
