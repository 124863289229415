@import "~cvent-event-calendar/lib/CalendarView/styles/CalendarViewWidget.less";

.container {
  .container();
  background-color: transparent;
  border-radius: 0px;
}

.listItem {
  .listItem;
  border-color: rgb(221, 226, 230);
}

.webWidgetWrapper {
  position: relative;
}

.calendarLoadSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.title {
  .title;
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  line-height: 1.3;
  background-color: transparent;
  border-radius: 0px;
  padding: 0px;
}

.dateIconWrapper {
  .dateIconWrapper;
  .dateIcon {
    color: rgb(4, 21, 50);
  }
}

.locationIconWrapper {
  .locationIconWrapper;
  .locationIcon {
    color: rgb(4, 21, 50);
  }
}

.date {
  .date;
  font-family: inherit;
  font-weight: 300;
  font-size: inherit;
  color: inherit;
  line-height: 1.3;
  background-color: transparent;
  border-radius: 0px;
  padding: 0px;
}

.location {
  .location;
  font-family: inherit;
  font-weight: 300;
  font-size: inherit;
  color: inherit;
  line-height: 1.3;
  background-color: transparent;
  border-radius: 0px;
  padding: 0px;
}

.description {
  .description;
  padding: 0px;
  font-size: 1em;
  line-height: 1.3;
  font-family: inherit;
  color: inherit;
  font-weight: 300;
  background-color: transparent;
  border-radius: 0px;
}

.linkColor {
  line-height: 1.5;
}

.listContainer {
  padding: 1.14em;
  background: @listBgColor;
  cursor: pointer;
  outline: none;
}

.listButtonContainer {
  .listContainer();
  background: @buttonBgColor;
  padding: 0 1.14em;
  border: 1px solid #ebebeb;
}

.filtersDialogContainer {
  .filtersDialogContainer;
  width: auto;
}
