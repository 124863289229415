@import "~cvent-event-calendar/lib/CalendarView/styles/CvFormElement.less";

.label {
  line-height: 1.3;
  font-family: inherit;
  color: inherit;
  font-weight: 300;
  background-color: transparent;
  border-radius: 0px;
  padding: 0px 0px 0.5em;
}

.textbox {
  font-family: inherit;
  font-weight: 300;
  color: inherit;
  text-align: left;
  line-height: 1.3;
  background-color: rgb(255, 255, 255);
}

.iconWrapper {
  color: rgb(39, 63, 105);
  font-size: 1.125em;
}
