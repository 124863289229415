.small {
  width: 100%;
  display: inline-block;
}

.medium {
  width: 100%;
  display: inline-block;
}

.large {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}

.hyphenElementHidden {
  display: none;
}

.leftSidePadding {
  padding-top: 0.625em;
  padding-left: 0;
}

.rightSidePadding {
  padding-right: 0;
}

.filtersSideBySide {
  width: 25%;
  float: left;
}

.dateTopPadding {
  margin-top: 0.625em;
}

.containerSideBySide {
  width: 75%;
  display: inline-block;
  position: relative;
}

.fullWidthContainer {
  width: 100%;
  display: inline-block;
  position: relative;
}

.dateRangeContainer {
  position: relative;
}
