@import "~cvent-event-calendar/lib/CalendarView/styles/Datepicker.less";

.textboxDate {
  font-family: inherit;
  font-weight: 300;
  color: inherit;
  text-align: left;
  line-height: 1.3;
  background-color: transparent;
  padding: .4em .4em .4em 1.75em;
}

.calendar {
  font-family: inherit;
}
