@import './Variables.less';
@import "~nucleus-core/less/MediaQuery.less";
@import "./CvFormLabels.less";
@import "./Breakpoint.less";

// Base widget Container //
.container {
  position: relative;
  overflow: visible;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  font-size: @baseFont;
}

.mainContainer {
  padding-top: 1.15em;
}

.content {
  padding: 1.15em;
  width:100%;
  display: inline-block;
  box-sizing: border-box;
}

.searchWrapper {
  .content();
  display: block;
}

.monthViewWrapper {
  .content();
  display: block;
}

.dateWrapper {
  margin-left: 0;
  @media only screen and (min-width: 651px) and (max-width: 991px) {
    margin-left: .81em;
  }
  label {
    color: rgba(0,0,0,0.8);
    width: auto;
    padding-left: 0;
    font-weight: normal;
    height: 1.5em;
    text-align: left;
    padding: 0 10px;
    width: 100%;
    @media @large {
      padding: 0;
    }
  }
}

.dateWrapperOuter {
  box-sizing: border-box;
  position: relative;
}

.filtersDialogContainer {
  padding: 1.15em;
  width: 100%;
}

.rightSide {
  padding-right:.625em;
  @media @small {
     padding-right: 0;
  }
}

.leftSide {
  padding-left:.625em;
  @media @small {
     padding-left: 0;
  }
}

.filtersDialogWrapper {
  div[class*=panelListItem] {
    border: 0;
  }
}

// Icon Styles //
.hyphenElement {
  position: absolute;
  top: 68%;
  left: 49.3%;
  right: -.313em;
  transform: translateY(-50%);
  font-size: 1.25em;
  line-height: 1;
  @media @small {
    display: none;
  }
}

.iconStyle {
  color: @iconColor;
  font-size: 1.25em;
  position: absolute;
  top: .313em;
  background: inherit;
}

.clear {
  .iconStyle();
  right: .3em;
  cursor: pointer;
}

.clearIconWrapper {
  display: inline;
}

.header {
  font-family: inherit;
  font-size: 1em;
  font-weight: 600;
  line-height: 1em;
  color: rgba(0,0,0,0.8);
  margin: 1.15em 0;
  display: none;
}


.emptyMessage {
  font-size: .875em;
  color: #303030;
  text-align: center;
  padding: 30px;
}

// CalendarList Styles //

.searchOverlayContainer {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;

  &:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    background-color: @searchOverlayColor;
  }
}

.searchOverlayContent {
  position: relative;
  display: inline-block;
  margin: 2em auto;
  padding: 1em 1em;
  border-radius: 0.25em;
  background-color: rgba(255,255,255,0.85);
}

.searchOverlayText {
  margin-top: 0.8em;
  font-size: 0.85em;
  font-weight: 700;
  color: rgba(0,0,0,0.9);
}

.loadMoreError {
  text-align: center;
  font-size: 1em;
  color: @errorMessageColor;
  padding: .8em 0;
  border-top: 1px solid @listSeperator;
}

.listContainer {
  width: 100%;
  padding: 1.14em;
  background: @listBgColor;
  cursor: pointer;
  outline: none;
}

.listButtonContainer {
  width: 100%;
  background: @listBgColor;
  cursor: pointer;
  padding: 0 1.14em;
}

.listButtonContainer > button {
  &:focus {
    outline-width: medium;
    outline-style: solid;
    outline-color: indigo;
  }
}

.listWrapper {
  padding: 0!important;
  margin: 0!important;
  list-style: none;
}

.listItem {
  padding: 0!important;
  margin: 0!important;
  border-top: 1px solid @listSeperator;
  &:first-child {
    border-top: none;
  }
}
.title {
  color: @textColor;
  display: block;
  font-size: 1.14em;
  font-weight: bold;
  line-height: 1.25;
  margin: 0 0 .35em 0;
  padding: 0 .313em 0 0;
}

.moreInfo {
  margin-bottom: .6em;
}

.date {
  color: @textColor;
  margin-bottom: 0;
  display: inline-block;
  line-height: 1.4;
  vertical-align: text-top;
}

.location {
  color: @textColor;
  margin-bottom: .1em;
  line-height: 1.4;
  vertical-align: text-top;
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  line-height: 1.5;
  color: @textColor;
  margin-bottom: .35em;
}

.linkColor {
  line-height: 1.5;
}

.displayIcon {
  position: relative;
  font-size: 1em;
  padding-left: 1.4em;
}

.smallIcon {
  position: absolute;
  color: @iconColor;
  font-size: 1.15em;
  line-height: 1.15;
}

.dateIconWrapper {
  .displayIcon;
  margin-bottom: .35em;
  .dateIcon {
    .smallIcon;
    left: 0;
    top: .05em;
  }
}

.locationIconWrapper {
  .displayIcon;
  margin-bottom: .45em;
  .locationIcon {
    .smallIcon;
    left: .025em;
    top: .05em;
  }
}

// Browser Specific items //
::-webkit-input-placeholder {
   color: @searchPlaceholderTextColor;
}

/* Firefox 19+ */
::-moz-placeholder {
   color: @searchPlaceholderTextColor;
}

/* edge */
:-ms-input-placeholder {
   color: @searchPlaceholderTextColor;
}
