@import '~cvent-event-calendar/lib/CalendarView/styles/InteractiveFiltersPanel.less';
@import '~cvent-event-calendar/lib/CalendarView/styles/FiltersDialog.less';
@import './LocationFilter.less';

.checkbox {
  list-style: none!important;
  padding: 0!important;
  margin-top: 0!important;
  margin-bottom: 1.15em!important;

  li {
    padding-top: 0.5em!important;
    padding-bottom: 0!important;
  }

  li:first-child {
    padding-top: 0!important;
  }
}

.showMoreLink {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.resetAllLink {
  color: inherit;
  &:hover {
    color: inherit;
  }
}
