@import './CvFormElement.less';
@import '~cvent-event-calendar/lib/CalendarView/styles/LocationFilter.less';

.suggestionMenuWrapper {
  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
