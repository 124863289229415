@import  (reference) "Variables.less";

.container {
  background: @haderBgColor;
}

.groupHeader {
  color: @textColor;
  background: inherit;
  text-align: center;
  font-size: 1.14em;
  font-weight: 900;
  line-height: 1.25;
  padding: .6em 1em;
  margin: 0;
}
