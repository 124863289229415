@import './Variables.less';

.locationContainer {
  padding: 1.15em 0;
}

.locationDialogContainer {
  padding-bottom: 1em;
}

.inputWrapper {
  position: relative;
  clear: both;
  input {
    padding-right: 1.75em;
  }
}

.locationIconWrapper {
  position: absolute;
  left: .25em;
  right: auto;
  transform: translateY(-50%);
  font-size: 1.125em;
  line-height: 1;
  margin-top: -1em;
}

.closeIconWrapper {
  position: absolute;
  margin-top: -1.6em;
  right: 0.4em;
  left: auto;
  cursor: default;
}

.radiusInputWrapper {
  composes: inputWrapper;
  padding-top: 1.15em;

  div {
    display: inline;
    input {
      width: 50%;
      margin-right: 0.7em;
      padding: .4em;
    }
  }
}

/********** autocomplete styles **************/

.wrapper {
  position: relative;
  font-size: @hcFontSizeBase;
  height: 100%;
}

.suggestionMenuWrapper {
  position: absolute;
  margin-top: 2px;
  border: 1px solid @asapMediumGray;
  border-radius: 5px;
  overflow: hidden;
  background: @white;
  width: 100%;
  min-width: 300px;
  max-height: 425px;
  overflow-y: auto;
  z-index: @hcAutocompleteFlyoutZIndex;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}

.suggestionGroupHeader {
  padding: 12px 10px 0;
  font-size: @hcFontSizeXS;
  text-transform: uppercase;
  color: @hcGray;
}

.suggestion {
  padding: 6px 10px;
  font-weight: 400;
  cursor: pointer;
  line-height: 1.1;

  &.isHighlighted,
  &:hover {
    background: @asapLighterGray;
  }
}

.suggestionTextWrapper {
  position: relative;
  padding-left: 24px;
}

.suggestionIcon {
  font-size: @hcFontSizeMedium;
  color: @hcGray;
  position: absolute;
  left: 0;
}

.matchedString {
  color: @cventBlue;
  font-weight: 700;
}

.poweredByGoogle {
  height: 20px;
  background: url(../../../resources/images/powered_by_google_on_white.png) no-repeat right;
}

.iconFont {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cvticons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconLocation {
  .iconFont();
  position: absolute;
  left: 0;
}
.iconLocation:before {
  content: "\eaa6";
}
