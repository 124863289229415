@import "./Variables.less";
@import "~nucleus-core/less/MediaQuery.less";

.element {
  font-size: 1em;
  background: none;
}

.container{
  padding-top: 0.938em;
}

.inputContainer {
  padding: 0 1px;
  padding-left: 0;
  @media @small {
    margin-bottom: 0;
  }
}

.inputContainer + .inputContainer {
  @media @large {
    padding: 0 .625em;
  }
}

.iconWrapper {
  position: absolute;
  left: .25em;
  top: 52%;
  right: auto;
  transform: translateY(-50%);
  font-size: 1.25em;
  line-height: 1;
}

.inputWrapper {
  position: relative;
}

.inputWrapperAlt {
  .inputWrapper();
  padding: 0 .625em 0 0;
  @media @large {
    margin-bottom: .625em;
    padding: 0;
  }
}

.label {
  padding: 0px;
  font-family: inherit;
  font-weight: 400;
  font-size: 1em;
  color: rgba(0,0,0,.8);
  /* above all are the config options for label */
  width: 100%;
  display: block;
  line-height: 1;
  margin: 0;
  word-wrap: break-word;
}

.textbox {
  font-family: inherit;
  font-weight: 300;
  font-size: 1em;
  height: 2em;
  color: rgba(0,0,0,0.65);
  padding: .4em .4em .4em 1.75em;
  background: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,.2);
  border-radius: 3px;
  /* above all are the config options for input */
  width: 100%;
  line-height: 1;
  box-sizing: border-box;
  text-align: left;
  margin: 0;
  box-sizing: border-box;
}

.textbox::-ms-clear {
  display: none;
}

// Icon Styles //
.iconStyle {
  color: @iconColor;
  font-size: 1.25em;
  position: absolute;
  top: .313em;
  background: inherit;
}

.search {
  .iconStyle();
  left: .3em;
}

.errorContainer {
  position: absolute;
  bottom: -1.35em;
  left: 0;
}

.error{
  border-color: @errorColor !important;
}

.errorText{
  color: @errorColor;
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
}

.hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.required {
  color: @pastelRed;
}
