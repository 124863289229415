@import '~nucleus-core/less/cv/InteractiveFiltersPanel.less';

.panel {
  padding: 1.15em;
  float: none;
}

.panelHeaderText {
  padding-top: 0;
}

.filterListTitle {
  word-break: break-word;
  word-wrap: break-word;
}

.checkbox {
  margin-bottom: 1.15em;

  li {
    padding-bottom: 0;

    input[type=checkbox] + label {
      word-break: break-word;
      word-wrap: break-word;
    }

    input[type=checkbox] + label:before {
      font-size: inherit;
      margin: 0.01em;
    }

    input[type=checkbox]:checked + label:after {
      font-size: inherit;
    }
  }
}
