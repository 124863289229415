@import "~cvent-event-calendar/lib/CalendarView/styles/HeaderStyles.less";

.groupHeader {
  padding: 0.4em 1em;
  line-height: 1.3;
  font-family: inherit;
  color: inherit;
  font-weight: 700;
  background-color: transparent;
  border-radius: 0px;
}
