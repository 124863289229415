@import "~nucleus-core/less/MediaQuery.less";

.clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@fontSize: 12px;

@labelWidth: 338px;
@inputContainerWidth: 560px;
@inputTextWidth: 400px;
@inputTextSmall: 100px;
@selectWidth: 250px;
@minWidth: 940px;

@labelColor: #2d5681;
@requiredColor: #ff0000;
@validColor: #17a455;
@errorColor: #ff0000;

@toggleHeight: 20px;
@toggleWidth: 40px;

.label {
  float: left;
  width: @labelWidth;
  display: block;
  text-align: right;
  min-height: 1px;
  box-sizing: border-box;
  font-weight: bold;
  color: @labelColor;
  padding: 10px;

  @media @medium {
    float: none;
    text-align: left;
    width: auto;
    & > span {
      margin: 0;
    }
    .clearfix();
  }
}

.hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
}

.required {
  color: @requiredColor;
}
