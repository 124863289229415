@import "~cvent-event-calendar/lib/CalendarView/styles/ButtonStyles.less";

.button {
  background: transparent;
  border: 0px;
  color: @buttonFontColor;
  font-size: 1em;
  width: 100%;
  height: 2.5em;
  cursor: pointer;

  &:disabled {
    background: transparent;
    border: none;
    color: @buttonDisabledColor;
    padding: .35em 0;
  }
}
